<template>
  <div class="points-box">
    <span class="heading">{{ heading }} </span>
    <span class="points-text"> {{ points }} </span>
  </div>
</template>

<script>
export default {
  name: 'Stats',
  components: {},
  props: {
    heading: String,
    points: [Number, String],
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.points-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 336px;
  padding: 30px;
  margin: auto;
  margin-bottom: 10px;
  background: #ffffff26 0% 0% no-repeat padding-box;
  border-radius: 7px;
  opacity: 1;
}
.heading {
  margin-bottom: 3.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.points-text {
  font-size: 28px;
}
</style>
